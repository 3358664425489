@import "/src/main";

.adminlist {
  margin-top: 50px;

  .heading {
    font-weight: bold;
    padding-bottom: 3px;
  }

  .adminCount {
    padding-bottom: 15px;
    font-weight: normal;
  }
  .admin {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #aaa;
    height: 40px;
    line-height: 40px;

    .label {
      flex-grow: 1;
    }
  }
  .admin:first-child {
    border-top: 1px solid #aaa;
  }

  .assign {
    margin-top: 20px;
    .assignButton {
      text-align: right;
    }
  }
}
