@import "/src/main";

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;

  .popup {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -300px;
    margin-top: -300px;
    width: 600px;
    height: 600px;
    border: 1px solid $color_primary;
    padding: 10px;

    text-align: center;

    display: flex;
    flex-direction: column;

    .heading {
      font-weight: bold;
    }

    .qrCode {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    @media print {
      .heading {
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 24px;
        font-weight: bold;
      }

      .qrCode {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      .buttons {
        display: none;
      }
    }
  }
}
