@import "/src/main";

.stats {
  .mainHeading {
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #aaa;
    .title {
      flex-grow: 1;
      padding-top: 15px;
    }

    .dropdownWrapper {
      margin-left: 5px;
      .dropdown {
        min-width: 150px;
        margin-top: 10px;
      }
    }
  }
  .btnBack {
    display: inline-block;
    margin-right: 20px;
  }

  .overview {
    margin-bottom: 20px;
  }

  .boxes {
    display: flex;
    flex-flow: row wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;

    .box {
      display: inline-block;
      width: 250px;
      height: 80px;
      position: relative;
      border: 1px solid #888;
      margin: 5px;

      cursor: pointer;

      .name {
        position: absolute;
        top: 10px;
        left: 15px;
        font-weight: bold;
      }
      .activites_per_day {
        position: absolute;
        top: 10px;
        right: 15px;
      }

      .subline {
        position: absolute;
        top: 30px;
        left: 15px;
        font-weight: normal;
        font-size: 0.8em;
      }

      .dot {
        border-radius: 50%;
        border: 2px solid white;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 35px;
        margin-bottom: 5px;
        color: black;
        line-height: 40px;
        font-size: 0.85em;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        position: absolute;
        bottom: 3px;
        right: 8px;
      }

      .bargraph_hz {
        border: none;
        width: 96%;
        height: 40px;

        position: absolute;
        bottom: 5px;
        right: 2%;

        .bar {
          display: inline-block;
          height: 40px;
        }
      }

      .red {
        background-color: #ff5757;
      }

      .yellow {
        background-color: #ffde59;
      }

      .green {
        background-color: #7dd957;
      }
    }
  }
  .boxes::after {
    content: "";
    flex: auto;
  }

  .chartContainer {
    border: 1px solid #888;
    overflow: hidden;

    .chart {
      padding: 15px 10px 10px 10px;
      height: 350px;
    }
  }

  .legendContainer {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;

    .legend {
      padding: 10px 20px 10px 0px;
      height: 26px;
      display: flex;
      flex-flow: row;

      .dot {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 8px 0px;
        display: inline-block;
        margin-right: 5px;
      }

      .text {
        display: inline-block;
        height: 26px;
        line-height: 26px;
      }

      .count {
        display: inline-block;
        height: 26px;
        line-height: 26px;
        padding-left: 10px;
        font-weight: 600;
        color: #444;
      }
    }
  }
}
