@import "/src/main";

.screen {
  padding: 0px 20px;
}

.pageHeading {
  //padding: 0px 10px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subHeading {
  //padding: 0px 10px;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.listeditor {
  display: flex;
  flex-direction: row;

  .list {
    margin-right: 20px;
  }
  .editor {
  }
}
