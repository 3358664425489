@import "/src/main";

.twograph {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .summary {
    width: 49%;
    max-width: 600px;
    height: 400px;
    border: 1px solid #888;
    position: relative;
    display: inline-block;
    overflow: hidden;

    .period {
      position: absolute;
      padding: 10px 10px;
      font-weight: bold;
      top: 0;
      left: 0;
    }

    .chart {
      margin-top: -25%;
      padding: 50px;
      z-index: -1;
      height: 400px;
      max-height: 400px;
    }

    .info {
      position: absolute;
      bottom: 21%;
      width: 100%;
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;

      .line2 {
        font-size: 0.8em;
        font-weight: normal;
      }
    }

    .legend {
      width: 60%;
      position: absolute;
      bottom: 5%;
      padding-left: 20%;
      padding-right: 20%;

      .item {
        width: 33%;
        display: inline-block;

        .dot {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          margin-left: 35px;
          margin-bottom: 5px;
        }

        .red {
          @extend .dot;
          background-color: $color_red;
        }

        .yellow {
          @extend .dot;
          background-color: $color_yellow;
        }

        .green {
          @extend .dot;
          background-color: $color_green;
        }

        .text {
          width: 100%;
          text-align: center;
          font-size: 0.9em;
        }
      }
    }
  }
  .monthly {
    @extend .summary;
    margin-left: 2%;

    .chart {
      margin-top: 3%;
      padding: 10px;
      height: 350px;
    }
  }
}
