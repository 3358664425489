@import "/src/main";

.box {
  display: inline-block;
  width: 250px;
  min-height: 80px;
  position: relative;
  border: 1px solid #888;
  margin: 5px;
  padding: 5px;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .grow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .name {
    font-weight: bold;
  }

  .dot {
    border-radius: 50%;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    line-height: 40px;

    color: black;
    font-size: 0.85em;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }

  .signoff {
    .activites_per_day {
    }
    .activites {
      font-size: 0.8rem;
    }
    .header {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      font-size: 0.8rem;
      justify-content: space-between;

      > div {
        // width: 0;
        // flex-grow: 1;
        // text-align: center;
      }
    }
    .percents {
      display: flex;
      flex-direction: row;
      font-size: 0.8rem;
      font-weight: bold;
      justify-content: space-between;

      > div {
        // width: 0;
        // flex-grow: 1;
        // text-align: center;
      }
    }

    .bargraph_hz {
      border: none;
      width: 100%;
      height: 40px;
      margin-top: 5px;
      background-color: #888;

      .bar {
        display: inline-block;
        height: 40px;
      }
    }
  }
  .subline {
    font-weight: normal;
    font-size: 0.8em;
  }

  .red {
    background-color: $color_red;
  }

  .yellow {
    background-color: $color_yellow;
  }

  .green {
    background-color: $color_green;
  }
}

.clickable {
  cursor: pointer;
}
