$min_app_width: 1000px;

$color_primary: #3d939f;
$color_secondary: #ff6e00;

$color_bg_primary: #21293d;
$color_bg_secondary: #3e485f;

$color_grey_darker: #444;
$color_grey_dark: #666;
$color_grey_mid: #888;
$color_grey_light: #aaa;
$color_grey_lighter: #ccc;
$color_grey_lightest: #eee;
$color_grey_lightest_extra: #f4f4f4;

$color_text_dark: #444;
$color_text_dark_secondary: #888;
$color_text_dark_disabled: #ccc;
$color_text_light: #ffffff;

$color_footer_bg: #444;

$color_bg_light: #e0e0e0;
$color_bg_lighter: #f0f0f0;
$color_bg_lightest: #fcf8fc;
$color_bg_white: #ffffff;

$color_error: red;

$color_warning_bg: #e01717;

// WELLBEING
$color_mon: #c8e4ac;
$color_tue: #99daf9;
$color_wed: #f6f7fb;
$color_thu: #e7b47f;
$color_fri: #fefa93;
$color_sat: #f5c4e5;
$color_sun: #f7cfcd;

$color_green: #3fa149;
$color_yellow: #ffce00;
$color_red: #ff0000;

$font_xs: 0.8rem;
$font_s: 0.9rem;
$font_l: 1.2rem;
$font_xl: 1.5rem;
$font_2xl: 1.8rem;
$font_3xl: 2rem;

.fade {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeHidden {
  opacity: 0;
  pointer-events: none;
}

a:hover {
  cursor: pointer !important;
  color: $color_primary !important;
  text-decoration: underline;
}

$singleContentWidth: 600px;
$singleContentWidthSmall: 460px;

.singleContent {
  width: $singleContentWidth;
  min-width: $singleContentWidth;
  max-width: $singleContentWidth;

  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 20px 30px;
  background-color: $color_bg_white;

  border: 1px solid $color_grey_lighter;
}

.singleContentSmall {
  @extend .singleContent;
  width: $singleContentWidthSmall;
  min-width: $singleContentWidthSmall;
  max-width: $singleContentWidthSmall;
}
