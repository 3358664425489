@import "/src/main";

.form {
  margin-top: 100px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.heading {
  font-weight: bold;
  margin-bottom: 3px;
}
.info {
  margin-bottom: 15px;
  line-height: 130%;
}

.textinput,
.select {
  margin-bottom: 5px !important;
}

.textinput:last-of-type {
  margin-bottom: 20px !important;
}

.button {
  margin-top: 5px !important;
}
.button:first-of-type {
  margin-top: 0px !important;
}

.buttonInline {
  margin-left: 5px !important;
}
.buttonInline:first-of-type {
  margin-left: 0px !important;
}

.MuiButton-outlinedPrimary {
  color: $color_primary !important;
}

.error {
  margin-top: 20px;
  color: #800000;
}
