@import "/src/main";

.list {
  width: 300px;
  background-color: #fff;
  cursor: pointer;

  .searchbox {
    margin-bottom: 5px;
  }

  .items {
    width: 100%;
    background-color: #eee;
  }

  .listitem {
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  .listitem:last-of-type {
    border-bottom: none;
  }

  .listitem_selected {
    background-color: #ddd;
    font-weight: bold;
  }

  .newButton {
    margin-bottom: 15px;
  }

  .itemlabel {
    padding: 10px 20px;
  }
}

.editor {
  width: 750px;
  max-width: 750px;
  .buttons {
    display: flex;
    flex-direction: row;
    .delete {
      flex-grow: 1;
    }
    .buttons {
      text-align: right;
    }
  }
}
