@import "/src/main";

.wellbeing {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .wellbeing_calendar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    max-width: 560px;
    margin-right: 25px;

    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 80px;

      .cell {
        background-color: rgba(255, 255, 255, 0.5);
        text-align: center;
        height: 60px;
        line-height: 35px;
        padding: 0px 5px 0px 5px;
        cursor: pointer;

        .indicator {
          display: flex;
          flex-direction: row;
          .block {
            width: 1px;
            height: 8px;
            flex-grow: 1;
          }
          .happy {
            @extend .block;
            background-color: $color_green;
          }
          .neutral {
            @extend .block;
            background-color: $color_yellow;
          }
          .sad {
            @extend .block;
            background-color: $color_red;
          }
        }
      }
      .cell:nth-of-type(2) {
        padding-top: 10px;
      }
      .empty {
        @extend .cell;
        cursor: initial;
      }
      .selected {
        @extend .cell;
        background-color: rgba(0, 0, 0, 0.25);
      }
      .head {
        @extend .cell;
        height: 35px;
        font-weight: bold;
        text-transform: uppercase;
        background-color: initial;
        cursor: initial;
      }
    }

    .column:nth-of-type(1) {
      background-color: $color_mon;
    }
    .column:nth-of-type(2) {
      background-color: $color_tue;
    }
    .column:nth-of-type(3) {
      background-color: $color_wed;
    }
    .column:nth-of-type(4) {
      background-color: $color_thu;
    }
    .column:nth-of-type(5) {
      background-color: $color_fri;
    }
    .column:nth-of-type(6) {
      background-color: $color_sat;
    }
    .column:nth-of-type(7) {
      background-color: $color_sun;
    }
  }

  ///////////////////////////////////////////////////////////////

  .legend_box {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: $color_grey_lightest_extra;
    border: 2px solid $color_grey_light;
    flex-grow: 1;
    max-width: 500px;

    .head {
      position: absolute;
      display: flex;
      flex-direction: row;
      width: 100%;

      .title {
        font-weight: bold;
        padding: 10px;
      }
      .close {
        position: absolute;
        right: 0;
        float: right;
      }
    }
  }

  .dot_item {
    .time {
      display: inline-block;
      width: 50px;
      height: 20px;
      line-height: 20px;
    }
    .dot {
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

    .title {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
    }

    .info {
      font-size: 0.9rem;
    }
  }

  .sad {
    @extend .dot_item;
    .dot {
      background-color: $color_red;
    }
  }

  .neutral {
    @extend .dot_item;
    .dot {
      background-color: $color_yellow;
    }
  }

  .happy {
    @extend .dot_item;
    .dot {
      background-color: $color_green;
    }
  }

  ///////////////////////////////////////////////////////////////
  .wellbeing_summary {
    @extend .legend_box;

    .chart_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;

      .chart {
        width: 200px;
        height: 200px;
      }
    }

    .chart_legend {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .dot_item {
        margin-bottom: 15px;
      }
      .dot_item:last-of-type {
        margin-bottom: 0px;
      }

      .legend {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }
    }
  }

  .wellbeing_daylist {
    @extend .legend_box;

    .list {
      width: 100%;
      margin-top: 50px;

      .content {
        padding: 0px 10px;

        .dot_item {
          display: flex;
          flex-direction: row;
          width: 100%;
          padding-bottom: 5px;
          margin-bottom: 15px;
          border-bottom: 1px solid $color_grey_light;
        }
        .dot_item:last-of-type {
          margin-bottom: 0px;
        }
      }
    }
  }
}
