@import "main";

.topMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  min-width: $min_app_width;
  background: $color_primary;
  color: white;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 1);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: inherit !important;
  }

  .logoWrapper {
    display: flex;
    padding: 5px 0px;
    margin-left: 32px;
    margin-right: 10px;
  }
  .logo {
    width: 45px;
  }

  .subMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: $font_l;
    margin-left: 10px;
    margin-right: 10px;

    .item {
      height: 36px;
      padding: 10px 30px 10px 30px;
      .subtext {
        font-size: 0.6em;
        text-transform: uppercase;
        font-weight: bold;
        color: #ccc;
        text-align: center;
      }
    }
    .selected {
      background-color: #888;
    }

    a {
      color: inherit;
      font-weight: 500;
    }

    a:last-child {
      padding-right: 0px;
    }
  }

  .subMenu:nth-child(2) {
    flex-grow: 5;
  }

  .topMenuButton {
    margin-left: 20px !important;
    color: $color_primary !important;
  }

  .accessLevel {
    height: 30px;
    line-height: 30px;
    background-color: white;
    color: #3d939f;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 10px;
    margin-right: 10px;
    white-space: nowrap;
  }
}
