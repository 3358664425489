@import "/src/main";

.homes {
  display: flex;
  flex-direction: row;

  .error {
    color: red;
  }
}
