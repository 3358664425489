@import "/src/main";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
.content {
  width: 600px;
  min-height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  margin-top: -150px;
  text-align: center;
}

.spinnerWrapper {
  margin-top: 50px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;

  background-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0px 0px 50px 50px rgba(255, 255, 255, 0.7);
  -moz-box-shadow: 0px 0px 50px 50px rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px 50px rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  padding: 5px;
}

.spinner {
  animation: rotation 2s infinite linear;
  width: 60px;
}

.spinnerText {
  font-weight: 600;
  font-size: $font_l;
  padding-top: 10px;
}

.resultWrapper {
  background-color: #fff;
  width: 90%;
  height: 100%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  text-align: justify;
  text-justify: auto;
  border: 2px solid #bbb;
}

.heading {
  font-weight: 600;
  font-size: $font_l;
  padding-bottom: 8px;
}
.desc {
  font-weight: 400;
  white-space: pre-wrap;
}

.continueButton {
  margin-top: 20px !important;
}
