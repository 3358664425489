@import "/src/main";

.app {
  display: flex;
  flex-direction: column;
  min-width: $min_app_width;
  min-height: 100%;
  background: $color_bg_white;
  color: $color_text_dark;
  padding-top: 65px;
  padding-bottom: 56px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  min-width: $min_app_width;
}
