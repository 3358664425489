@import "/src/main";

.calendar {
  .control {
    border-bottom: 1px solid black;
    padding-top: 10px;
    padding-bottom: 20px;
    .title {
      display: inline-block;
      padding-right: 20px;
      font-weight: bold;
      font-size: 1.3rem;
    }
    .selectDate {
      display: inline-block;
      margin-left: 20px;
    }
    .dateShowing {
      display: inline-block;
      width: 130px;
      height: 32px;
      line-height: 32px;

      margin: 0px 5px;
      text-align: center;
    }
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;

    .cell {
      display: flex;
      flex-direction: row;

      width: 40px;
      flex-grow: 1;
      padding: 3px;
      border: 1px solid $color_grey_lighter;
      border-top: 0px;
      border-right: 0px;

      .activity {
        display: inline-block;
        flex-grow: 1;
        padding: 5px 8px;
        margin-right: 3px;
        border-radius: 5px;
        border: 2px solid rgba(0, 0, 0, 0.3);
        overflow: hidden;

        .line {
          display: flex;
          flex-direction: row;
          font-size: 0.8rem;
          color: #666;
          padding-bottom: 3px;
          .startTime {
            flex-grow: 1;
          }
          .objective {
            flex-grow: 0;
          }
        }

        .name {
          font-weight: bold;
        }
      }
      .activity:last-of-type {
        margin-right: 0px;
      }
    }

    .cell:last-of-type {
      border-right: 1px;
    }

    .header {
      @extend .cell;
      display: inline-block;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;

      border-left: 0px;
    }

    .time {
      @extend .cell;
      display: inline-block;
      border-left: 0px;
      flex-grow: 0;
      width: 50px;
      text-align: center;
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}
