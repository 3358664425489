@import "/src/main";

.activity_table {
  width: 100%;
  border-collapse: collapse;
  th {
    text-align: left;
  }

  th,
  td {
    padding: 2px 4px;
    line-height: 24px;
  }

  tr {
    border-bottom: 1px solid #ccc;
  }

  .row {
    .item {
    }
  }

  .color {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 4px 5px 4px 0px;
  }

  .objective {
    display: flex;
    flex-direction: row;
  }

  .status {
    display: flex;
    flex-direction: row;
    .dot {
      @extend .color;
      border-radius: 50%;
    }

    .not_performed {
      @extend .dot;
      background-color: $color_red;
    }

    .unable {
      @extend .dot;
      background-color: $color_yellow;
    }

    .performed {
      @extend .dot;
      background-color: $color_green;
    }
  }
}
